import {Injectable} from "@angular/core";
import {AuthConfig, NullValidationHandler, OAuthService, UserInfo} from "angular-oauth2-oidc";
import {ActivatedRoute, Router} from "@angular/router";
import {Environment} from "../environment";


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user: UserInfo;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private oauthService: OAuthService,
        private environment: Environment
    ) {
    }

    async initAuth(): Promise<any> {
        return new Promise<void>((resolveFn, rejectFn) => {

            let authConfig: AuthConfig = {
                issuer: this.environment.issuer + 'admin',
                requireHttps: this.environment.requireHttps,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                clientId: this.environment.clientId,
                scope: this.environment.scope,
                responseType: this.environment.responseType,
                disableAtHashCheck: this.environment.disableAtHashCheck,
                showDebugInformation: this.environment.showDebugInformation
            };
            this.oauthService.configure(authConfig);
            this.oauthService.setStorage(localStorage);
            this.oauthService.tokenValidationHandler = new NullValidationHandler();

            this.oauthService.loadDiscoveryDocumentAndLogin().then(
                (isLoggedIn) => {
                    if (isLoggedIn) {
                        console.log('auth - is logged in:' + isLoggedIn);
                        this.oauthService.setupAutomaticSilentRefresh();
                        this.oauthService.loadUserProfile().then(
                            (data) => {
                                this.user = data;
                                this.setUser(this.user);
                                console.log('auth - load user info loaded');
                                // resolveFn();
                            },
                            (reason => {
                                console.log('auth - can not load user info');
                                this.oauthService.initImplicitFlow();
                                rejectFn();
                            })
                        );
                        // this.router.navigate([], {relativeTo: this.route}); // TODO remove query params
                        // this.router.navigate(['/']);
                        console.log('auth - completed');
                        resolveFn();
                    } else {
                        this.oauthService.initImplicitFlow();
                        rejectFn();
                    }
                },
                (error) => {
                    console.log({error});
                    if (error.status === 400) {
                        location.reload();
                    } else {
                        console.log('auth - error, logoff!');
                        localStorage.clear();
                        this.deleteAllCookies();
                        this.logoff();
                        rejectFn();
                    }
                }
            );
        });
    }

    getParameterFromUrl(variable: string) {
        let query = window.location.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    public getUser(): UserInfo {
        return this.user;
    }

    public setUser(user: UserInfo) {
        this.user = user;
    }

    public login() {
        this.oauthService.initLoginFlow();
    }

    public deleteAllCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    public logoff() {
        this.oauthService.revokeTokenAndLogout().then(() => {
            localStorage.clear();
        });
    }

    public getClaims(): object {
        return this.oauthService.getIdentityClaims();
    }
}
