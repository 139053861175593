import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './notfound/not-found.component';
import { AuthGuard } from './auth/auth.guard';

const routes: Routes = [
    {
        path: 'tenants',
        loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule), canActivate: [AuthGuard]
    },
    {
        path: 'runtimes',
        loadChildren: () => import('./runtime/runtime.module').then(m => m.RuntimeModule), canActivate: [AuthGuard]
    },
    {
        path: 'infrastructures',
        loadChildren: () => import('./infrastructure/infrastructure.module').then(m => m.InfrastructureModule), canActivate: [AuthGuard]
    },
    {
        path: 'tickets',
        loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule), canActivate: [AuthGuard]
    },
    {
        path: 'invoices',
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule), canActivate: [AuthGuard]
    },
    {
        path: 'transactions',
        loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule), canActivate: [AuthGuard]
    },
    {
        path: 'prices',
        loadChildren: () => import('./price/price.module').then(m => m.PriceModule), canActivate: [AuthGuard]
    },
    {
        path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
