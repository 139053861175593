/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataWarehouseOptions } from './dataWarehouseOptions';


export interface DataWarehouse { 
    id?: string;
    name?: string;
    type?: string;
    description?: string;
    version?: string;
    options?: DataWarehouseOptions;
    owner?: string;
    password?: string;
    username?: string;
    identity_id?: string;
    infrastructure_id?: string;
    public_url?: string;
    status?: string;
    created?: string;
    updated?: string;
    labels?: { [key: string]: string; };
    metadata?: { [key: string]: object; };
}

