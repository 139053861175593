/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Details1 } from './details1';
import { PaypalDetailsAllOf } from './paypalDetailsAllOf';


export interface PaypalDetails extends Details1 { 
    type?: string;
    payer_email?: string;
    payer_id?: string;
}

