/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Amount } from './amount';


export interface Price { 
    id?: string;
    amount?: Amount;
    product_type?: string;
    product_reference?: string;
    created?: string;
    metadata?: { [key: string]: object; };
}

