/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Activity } from './activity';
import { Reaction } from './reaction';
import { CatalogActivityAllOf } from './catalogActivityAllOf';
import { Catalog } from './catalog';


/**
 * Object CatalogActivity.
 */
export interface CatalogActivity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
    type?: string;
    actor: User1;
    target: Catalog;
    verb: string;
}

