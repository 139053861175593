/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Parameter } from './parameter';


/**
 * Metadata object.
 */
export interface Metadata { 
    name: string;
    description: string;
    parameters: Array<Parameter>;
}

