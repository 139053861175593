/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistinctTask } from './distinctTask';
import { FilterTask } from './filterTask';
import { JoinTask } from './joinTask';
import { TrainModelTask } from './trainModelTask';
import { PrintTask } from './printTask';
import { DropTask } from './dropTask';
import { OrderTask } from './orderTask';
import { RenameTask } from './renameTask';
import { WriteTask } from './writeTask';
import { AggTask } from './aggTask';
import { PredictModelTask } from './predictModelTask';
import { ReadTask } from './readTask';


/**
 * Creates a DAG (Directed Acyclic Graph) from a JSON file.  Attributes ---------- id : str     Identifier of the DAG. tasks : ListIntVisited     Tasks that make up the DAG.  Methods ------- check_dependencies(tasks)     Checks if the tasks dependencies are valid. check_circle(tasks)     Checks that there are no circular dependencies in the DAG. get_zipped_code(language: LanguageType)     Returns the requirements and code in a zipped folder. get_nodes(tasks)     Returns all the information of the DAG as a list of tasks. get_edges(tasks)     Get all two-by-two dependencies between tasks. to_graph(tasks)     Create a graph from the DAG with the edges and nodes.
 */
export interface DagUnverified { 
    /**
     * Identifier of the Directed Acyclic Graph (DAG).
     */
    id: string;
    /**
     * List of tasks that compose the DAG.
     */
    tasks: Array<AggTask | DistinctTask | DropTask | FilterTask | JoinTask | OrderTask | ReadTask | RenameTask | TrainModelTask | WriteTask | PredictModelTask | PrintTask>;
}

