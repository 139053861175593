/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Task } from './task';
import { Schedule } from './schedule';
import { Notifications } from './notifications';


export interface Workflow { 
    id?: string;
    name?: string;
    description?: string;
    project_id?: string;
    identity_id?: string;
    created?: string;
    updated?: string;
    tasks?: Array<Task>;
    schedule?: Schedule;
    notifications?: Notifications;
    parameters?: Array<string>;
    labels?: { [key: string]: string; };
    metadata?: { [key: string]: object; };
}

