/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Target } from './target';


export interface Device { 
    id?: string;
    name?: string;
    type?: string;
    description?: string;
    location?: string;
    country?: string;
    created?: string;
    updated?: string;
    target?: Target;
    allowed_instance_types?: Array<string>;
    labels?: { [key: string]: string; };
    properties?: { [key: string]: string; };
    metadata?: { [key: string]: object; };
}

