/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DistinctTask } from './distinctTask';
import { FilterTask } from './filterTask';
import { JoinTask } from './joinTask';
import { TrainModelTask } from './trainModelTask';
import { PrintTask } from './printTask';
import { DropTask } from './dropTask';
import { OrderTask } from './orderTask';
import { RenameTask } from './renameTask';
import { WriteTask } from './writeTask';
import { AggTask } from './aggTask';
import { PredictModelTask } from './predictModelTask';
import { ReadTask } from './readTask';


/**
 * DagUnverified with verified schema and framework compatibility.
 */
export interface Dag { 
    /**
     * Identifier of the Directed Acyclic Graph (DAG).
     */
    id: string;
    /**
     * List of tasks that compose the DAG.
     */
    tasks: Array<AggTask | DistinctTask | DropTask | FilterTask | JoinTask | OrderTask | ReadTask | RenameTask | TrainModelTask | WriteTask | PredictModelTask | PrintTask>;
}

