/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DataWarehouseOptions } from './dataWarehouseOptions';
import { ManagedOptions } from './managedOptions';
import { TrinoOptionsAllOf } from './trinoOptionsAllOf';


export interface TrinoOptions extends DataWarehouseOptions { 
    env?: { [key: string]: string; };
    instance_type?: string;
    type?: string;
    number_workers?: number;
    conf?: { [key: string]: object; };
}

