/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Options } from './options';
import { Schedule } from './schedule';
import { Library } from './library';
import { Notifications } from './notifications';


export interface Job { 
    id?: string;
    name?: string;
    description?: string;
    project_id?: string;
    identity_id?: string;
    owner?: string;
    created?: string;
    updated?: string;
    timeout_seconds?: number;
    continuous?: boolean;
    max_retries?: number;
    secrets?: Array<string>;
    libraries?: Array<Library>;
    options?: Options;
    schedule?: Schedule;
    notifications?: Notifications;
    parameters?: Array<string>;
    labels?: { [key: string]: string; };
    metadata?: { [key: string]: object; };
}

