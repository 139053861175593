<clr-main-container>

    <clr-header>
        <div class="branding">
            <a [routerLink]="['/']" class="nav-link">
                <cds-icon shape="step-forward-2"></cds-icon>
                <span class="title">Admin</span>
            </a>
        </div>
        <div class="header-actions">
            <span class="nav-link">
                <cds-icon shape="bug" style="position: static; transform: translate3d(0px, 0px, 0px);"></cds-icon>
                <span class="title">{{platformVersion}}</span>
            </span>
            <clr-dropdown *ngIf="user()">
                <button class="nav-text" clrDropdownTrigger>
                    {{$any(user()).preferred_username}}
                    <cds-icon shape="angle" direction="down"></cds-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <a (click)="logoff()" clrDropdownItem>Log out</a>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </clr-header>
    <div class="content-container">
        <clr-vertical-nav>
            <a clrVerticalNavLink routerLink="/">Overview</a>
            <a clrVerticalNavLink routerLink="/tenants" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="folder-open"></cds-icon>
                Tenants</a>
            <a clrVerticalNavLink routerLink="/tickets" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="bubble-exclamation"></cds-icon>
                Tickets</a>
            <a clrVerticalNavLink routerLink="/prices" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="dollar-bill"></cds-icon>
                Catalog</a>
            <a clrVerticalNavLink routerLink="/infrastructures" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="landscape"></cds-icon>
                Infrastructures</a>
            <a clrVerticalNavLink routerLink="/runtimes" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="host-group"></cds-icon>
                Runtimes</a>
            <a clrVerticalNavLink routerLink="/invoices" routerLinkActive="active">
                <cds-icon clrVerticalNavIcon shape="host-group"></cds-icon>
                Invoices</a>
        </clr-vertical-nav>
        <main class="content-area" style="padding-top: 0px;">
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>