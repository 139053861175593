/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Reaction } from './reaction';


/**
 * Object Activity.
 */
export interface Activity { 
    data?: object;
    id?: string;
    time?: string;
    reaction_count?: Array<Reaction>;
}

