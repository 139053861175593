/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Role } from './role';
import { RoleAssignment } from './roleAssignment';
import { Principal } from './principal';


export interface RoleAndPrincipalAndAssignment { 
    role?: Role;
    assignment?: RoleAssignment;
    principal?: Principal;
}

