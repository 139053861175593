/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EtlDagSchemasEnumsLanguageType } from './etlDagSchemasEnumsLanguageType';
import { CodeAction } from './codeAction';
import { Dag } from './dag';
import { ValidityAction } from './validityAction';


/**
 * Actions on the DAG.
 */
/**
 * @type Action1
 * Actions on the DAG.
 * @export
 */
export type Action1 = CodeAction | ValidityAction;

