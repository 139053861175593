/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Details1 } from './details1';


export interface PaymentMethod { 
    id?: string;
    details?: Details1;
    provider?: string;
    status?: string;
    remote_payment_method_id?: string;
    favorite?: boolean;
    metadata?: { [key: string]: object; };
    created?: string;
    updated?: string;
}

