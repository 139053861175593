/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { User1 } from './user1';
import { Project1 } from './project1';


/**
 * Object Follow.
 */
export interface Follow { 
    tenant_id?: string;
    resource?: User1 | Project1;
    follower?: User1;
}

