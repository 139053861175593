/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServicesRuntimes } from './servicesRuntimes';


export interface Services { 
    logging?: boolean;
    workflow?: boolean;
    nvidia?: boolean;
    scheduler?: boolean;
    runtimes?: ServicesRuntimes;
}

