/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Pageable } from './pageable';
import { Page } from './page';
import { JobPageAllOf } from './jobPageAllOf';
import { Job } from './job';


/**
 * typed Page
 */
export interface JobPage { 
    /**
     * Returns the number of the current page
     */
    number?: number;
    /**
     * Returns the size of the page
     */
    size?: number;
    /**
     * Returns the total amount of elements
     */
    total?: number;
    /**
     * Returns whether the current page is the first one.
     */
    first?: boolean;
    /**
     * Returns whether the current page is the last one.
     */
    last?: boolean;
    /**
     * Returns the number of total pages.
     */
    pages?: number;
    query?: Pageable;
    content?: Array<Job>;
}

