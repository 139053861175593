/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Capacity } from './capacity';
import { ByteCapacity } from './byteCapacity';


export interface TargetInfo { 
    total_nodes?: Capacity;
    total_cpus?: Capacity;
    total_memory?: ByteCapacity;
    availability_zones?: Array<string>;
}

