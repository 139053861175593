import {APP_INITIALIZER, NgModule} from "@angular/core";
import {OAuthModule, OAuthModuleConfig} from "angular-oauth2-oidc";
import {AuthenticationService} from "./authentication.service";
import {Environment} from "../environment";

export function oauthConfig(environment: Environment): OAuthModuleConfig {
    return {
        resourceServer: {
            allowedUrls: environment.allowedUrls,
            sendAccessToken: environment.sendAccessToken
        }
    };
}

@NgModule({
    imports: [OAuthModule.forRoot()],
    providers: [
        AuthenticationService,
        {
            provide: OAuthModuleConfig,
            deps: [Environment],
            useFactory: oauthConfig
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (initialAuthService: AuthenticationService) => () =>
                initialAuthService.initAuth(),
            deps: [AuthenticationService, OAuthModuleConfig],
            multi: true,
        }
    ]
})
export class AuthModule {
}