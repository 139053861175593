/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Options } from './options';
import { DbtOptionsAllOf } from './dbtOptionsAllOf';


export interface DbtOptions extends Options { 
    type?: string;
    profile?: string;
    adapter?: string;
    command?: string;
    target?: string;
}

