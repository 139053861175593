/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Uri } from './uri';


export interface Bucket { 
    id?: string;
    name?: string;
    description?: string;
    scope?: string;
    locked?: boolean;
    uri?: Uri;
    created?: string;
    updated?: string;
    metadata?: { [key: string]: object; };
    labels?: { [key: string]: string; };
}

