import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

    constructor(private oauthService: OAuthService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {

        let hasIdToken = this.oauthService.hasValidIdToken();
        let hasAccessToken = this.oauthService.hasValidAccessToken();

        return (hasIdToken && hasAccessToken);
    }
}