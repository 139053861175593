/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Library } from './library';


export interface FlinkOptionsAllOf { 
    type?: string;
    main_library?: Library;
    main_class_name?: string;
    loggers?: Array<string>;
    job_manager_instance_type?: string;
    task_manager_instance_type?: string;
    number_task_managers?: number;
}

