/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Library } from './library';


export interface SparkOptionsAllOf { 
    type?: string;
    libraries?: Array<string>;
    conf?: { [key: string]: object; };
    main_library?: Library;
    py_files?: Array<Library>;
    main_class_name?: string;
    loggers?: Array<string>;
    executor_instance_type?: string;
    driver_instance_type?: string;
    number_executors?: number;
}

