/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DockerOptionsAllOf } from './dockerOptionsAllOf';
import { Options } from './options';


export interface DockerOptions extends Options { 
    type?: string;
    credentials?: string;
    image?: string;
    port?: string;
    command?: string;
}

