/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Schedule } from './schedule';
import { CronScheduleAllOf } from './cronScheduleAllOf';


export interface CronSchedule extends Schedule { 
    type?: string;
    cron_expression?: string;
    timezone?: string;
    infrastructure_id?: string;
    device_id?: string;
}

