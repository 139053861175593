/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Options } from './options';
import { DaskOptionsAllOf } from './daskOptionsAllOf';


export interface DaskOptions extends Options { 
    type?: string;
    package_name?: string;
    module_name?: string;
    function_name?: string;
    number_workers?: number;
    number_workers_max?: number;
    driver_instance_type?: string;
    worker_instance_type?: string;
}

