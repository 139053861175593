import { Component, OnInit } from '@angular/core';
import '@cds/core/alert/register.js';
import '@cds/core/modal/register.js';

import '@cds/core/icon/register.js';
import {
    ClarityIcons,
    addTextIcon,
    angleIcon,
    applicationIcon,
    applicationsIcon,
    bellIcon,
    betaIcon,
    bookIcon,
    checkIcon,
    ciCdIcon,
    codeIcon,
    computerIcon,
    connectIcon,
    copyToClipboardIcon,
    cursorHandOpenIcon,
    dataClusterIcon,
    dollarIcon,
    downloadIcon,
    folderOpenIcon,
    historyIcon,
    homeIcon,
    hostGroupIcon,
    infoCircleIcon,
    keyIcon,
    linkIcon,
    lineChartIcon,
    mapMarkerIcon,
    newIcon,
    organizationIcon,
    pencilIcon,
    plusCircleIcon,
    plusIcon,
    popOutIcon,
    refreshIcon,
    searchIcon,
    shieldIcon,
    sliderIcon,
    starIcon,
    storageAdapterIcon,
    toolsIcon,
    userIcon,
    usersIcon,
    walletIcon,
    routerIcon,
    wifiIcon,
    objectsIcon,
    switchIcon,
    scatterPlotIcon,
    creditCardIcon,
    fileIcon,
    namespaceIcon,
    landscapeIcon,
    vmwAppIcon,
    barChartIcon,
    nvmeIcon,
    rewindIcon,
    administratorIcon,
    firewallIcon,
    mediaChangerIcon,
    flagIcon,
    processOnVmIcon,
    barsIcon,
    bubbleExclamationIcon,
    dollarBillIcon,
    loginIcon,
    bugIcon
} from '@cds/core/icon';
// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper";
import { UserInfo } from "angular-oauth2-oidc";
import { Environment } from "./environment";
import { AuthenticationService } from "./auth/authentication.service";

ClarityIcons.addIcons(
    addTextIcon,
    angleIcon,
    applicationIcon,
    applicationsIcon,
    bellIcon,
    betaIcon,
    bookIcon,
    checkIcon,
    ciCdIcon,
    codeIcon,
    computerIcon,
    connectIcon,
    copyToClipboardIcon,
    cursorHandOpenIcon,
    dataClusterIcon,
    dollarIcon,
    downloadIcon,
    folderOpenIcon,
    historyIcon,
    homeIcon,
    hostGroupIcon,
    infoCircleIcon,
    keyIcon,
    linkIcon,
    lineChartIcon,
    mapMarkerIcon,
    newIcon,
    organizationIcon,
    pencilIcon,
    plusCircleIcon,
    plusIcon,
    popOutIcon,
    refreshIcon,
    searchIcon,
    shieldIcon,
    sliderIcon,
    starIcon,
    storageAdapterIcon,
    toolsIcon,
    userIcon,
    usersIcon,
    walletIcon,
    routerIcon,
    wifiIcon,
    objectsIcon,
    switchIcon,
    scatterPlotIcon,
    creditCardIcon,
    fileIcon,
    namespaceIcon,
    landscapeIcon,
    vmwAppIcon,
    barChartIcon,
    nvmeIcon,
    rewindIcon,
    administratorIcon,
    firewallIcon,
    mediaChangerIcon,
    flagIcon,
    processOnVmIcon,
    barsIcon,
    bubbleExclamationIcon,
    dollarBillIcon,
    loginIcon,
    bugIcon
);

// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {

    platformVersion = '';

    constructor(private authenticationService: AuthenticationService, private environment: Environment) {
        this.platformVersion = this.environment.platformVersion;
    }

    user(): UserInfo {
        return this.authenticationService.getUser();
    }

    logoff() {
        this.authenticationService.logoff();
    }

    ngOnInit() {
    }
}
