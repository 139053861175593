/**
 * Tenant API
 * Tenant API
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: abc@layer.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SumParams } from './sumParams';
import { MinParams } from './minParams';
import { CountParams } from './countParams';
import { MaxParams } from './maxParams';
import { MeanParams } from './meanParams';


export interface AggParams { 
    /**
     * Column(s) to use to group the dataset.
     */
    group_columns: Array<string>;
    /**
     * List of aggregations to compute.
     */
    aggregations: Array<SumParams | MinParams | MaxParams | MeanParams | CountParams>;
}

